<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
onfield_page.title : "บันทึกงานซ่อม"
onfield_page.description : "ขั้นตอนที่ 4/8"
onfield_service_job.firmware_update.title : "การอัพเดทเฟิร์มแวร์"
onfield_service_job.firmware_update.message : "อัพเดทเฟิร์มแวร์หรือไม่"
onfield_service_job.firmware_update.detail: "เฟิร์มแวร์เดิม"
onfield_service_job.latest_firmware: "(เฟิร์มแวร์เป็น Version ล่าสุดแล้ว)"
onfield_service_job.action.update.confirm.title : "กรุณายืนยันเพื่อดำเนินการต่อไป"
onfield_service_job.action.update.confirm.message : "คุณต้องการดำเนินการไปยังขั้นตอนถัดไปหรือไม่ ?"
onfield_service_job.action.update.confirm.success : "ดำเนินการเรียบร้อย"
onfield_service_job.firmware_update.conjunction: "เฟิร์มแวร์ที่อัพเดท"
onfield_service_job.action.update.no_change : "ไม่ได้มีการแก้ไขค่า"
onfield_service_job.action.update.validate : "ข้อมูลบางส่วนไม่ถูกต้อง กรุณาตรวจสอบ"

action.ask_leave.title : "ยืนยันออกจากหน้าจอนี้"
action.ask_leave.message : "คุณได้แก้ไขข้อมูลอะไหล่ และ ยังไม่ได้บันทึกเข้าระบบ, ยืนยันต้องการยกเลิกการแก้ไขนี้ ?"
</i18n>
<template>
	<div class="page-padding">
		<my-page-header class="main-header" :title="$t('onfield_page.title')" :description="$t('onfield_page.description')" />
		<div class="background">
			<div class="firmware-update">
				<span class="repair-section-title">{{$t('onfield_service_job.firmware_update.message') + ': '}}</span>
				<span v-if="!isLatestFirmwareVer" >{{firmwareUpdate && targetFirmwareId ? $t('common.yes') : $t('common.no')}} </span>
				<span v-else>{{$t('common.no')}} {{$t('onfield_service_job.latest_firmware')}} </span>
			</div>
			<div v-if="targetFirmwareId && firmwareUpdate" class="firmware-update">
				<span class="repair-section-title">{{$t('onfield_service_job.firmware_update.detail', { firmwareUpdateDetail: service.droneData.firmware.name, targetFirmwareName: targetFirmwareName })}}</span>
				<span>{{service.droneData.firmware.name}}&nbsp;</span>
				<span class="repair-section-title">{{$t('onfield_service_job.firmware_update.conjunction')}}</span>
				<span class="title"> {{targetFirmwareName}}</span>
			</div>
		</div>

		<div class="background">
			<ServiceOnfieldRepair
				ref="repairRef"
				class="service_job-parts-pane"
				:error="componentError"
				:is-onsite="true"
				:service="service"/>
			<div class="page-action-right next-button">
				<a-button
					icon="right-circle"
					type="primary"
					@click="handleSave">
					{{$t('action.next.page')}}
				</a-button>
			</div>
		</div>
		<ServiceOnfieldFirmwareSelect
			v-if="service.droneData && latestFirmware"
			ref="firmwareSelect"
			:latest-firmware="latestFirmware"
			:firmware="service.droneData.firmware"
			@firmwareUpdate="firmwareChange"
			@cancelUpdate="cancelUpdate"/>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import ServiceObjectMixin from "@/src/mixins/service/ServiceObjectMixin.vue"
import ServiceOnfieldRepair from "@components/service/ServiceOnfieldRepair.vue"
import ServiceOnfieldFirmwareSelect from "@components/service/ServiceOnfieldFirmwareSelect.vue"
import axios from "axios"
import ModelError from "@utils/errors/ModelError"
import _isEqual from "lodash/isEqual"
import {scrollTopContent} from "@utils/formUtil"
import {REPAIR_PART_ACTION} from "@utils/serviceUtil"
import {mapGetters} from "vuex"

export default {
	components : {
		ServiceOnfieldRepair,
		ServiceOnfieldFirmwareSelect,
	} ,
	mixins : [PageMixin,ServiceObjectMixin] ,
	props : {
		otherDetails : {
			type : null,
			default : () => []
		} ,
	} ,
	data() {
		return {
			service: {},
			loading : false,
			currentServiceId : undefined,
			serviceParts : {} ,

			jobRepair : {} ,
			currentFirmwareId : undefined,

			ignorePreventExit : false,
			oldFormData : {} ,

			saveError : undefined,

			firmwareUpdate: false,
			targetFirmwareId: null,
			targetFirmwareName: null,
			repair: null,
			isLatestFirmware: false,
			latestFirmware: undefined,

			isClaimDrafted: false,
		}
	} ,
	computed : {
		...mapGetters('drone',['getLatestFirmwareBySet']) ,
		componentError() {
			return this.saveError instanceof ModelError ? this.saveError : undefined
		} ,
		isLatestFirmwareVer() {
			if (this.service.droneData && this.latestFirmware) {
				return this.service.droneData.firmware.id === this.latestFirmware.id
			} else {
				return false
			}
		} ,
		hasJobRepair() {
			const serviceId = this.$route.params.id
			return serviceId > 0 && this.jobRepair.id && this.jobRepair.id > 0
		} ,
	} ,
	watch : {
		$route() {
			this.fetchJobs()
		}
	} ,
	mounted() {
		this.fetchJobs()
		this.fetchService()
	} ,
	beforeMount() {
		window.addEventListener("beforeunload",this.preventExit)
	} ,
	beforeDestroy() {
		window.removeEventListener("beforeunload",this.preventExit)
	} ,
	methods : {
		cancelUpdate(cancel) {
			if (cancel)
				this.targetFirmwareId = null
		},
		firmwareChange(val) {
			if (this.currentFirmwareId !== val.id) {
				this.targetFirmwareId = val.id
				this.targetFirmwareName = val.name
			} else {
				this.targetFirmwareId = null
			}
		},
		firmwareUpdateModal() {
			this.latestFirmware = this.getLatestFirmwareBySet(this.service.droneData.firmware.firmware_set_id)
			if (this.service.droneData.firmware.id === this.latestFirmware.id) {
				this.isLatestFirmware = true
			} else {
				if (!this.firmwareUpdate) {
					this.$confirm({
						title : this.$t('onfield_service_job.firmware_update.title') ,
						content : this.$t('onfield_service_job.firmware_update.message') ,
						okText : this.$t('common.yes') ,
						cancelText : this.$t('common.no'),
						onOk: ()=> {
							this.firmwareUpdate = true
							this.visible = false
							this.$refs.firmwareSelect.openModal()
						} ,
						onCancel: ()=> {
							this.visible = false
						}

					})
				}
			}

		} ,
		fetchService() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}
			this.showPageLoading(true)
			axios.get("/api/services/"+serviceId).then((response)=>{
				this.service = response.data.data.service
				this.repair = response.data.data.repair
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
				this.firmwareUpdateModal()
			})
		} ,
		async fetchJobs() {
			const serviceId = this.$route.params.id
			if (!serviceId || this.currentServiceId == serviceId) {
				return
			}
			this.loading = true

			try {
				const responseJob = await axios.get(`/api/services/${serviceId}/jobs`)
				const jobData = responseJob.data.data || {}
				this.servicePriceListDocumentFiles = jobData.service_price_list_document_files
				this.serviceConsentDocumentFiles = jobData.service_consent_document_files
				this.currentFirmwareId = jobData.current_firmware_id
				this.serviceParts = jobData.service_parts

				this.jobRepair = ('job_repair' in jobData) ? jobData.job_repair : {}
				this.currentServiceId = serviceId
				const responseClaimRequest = await axios.get(`/api/services/${serviceId}/claim-request`)
				const claimRequestData = responseClaimRequest.data.data || {}
				this.isClaimDrafted = !!claimRequestData.claim_request.updated_datetime
				this.initJobs()
			} catch (error) {
				this.fetchError(error)
			} finally {
				this.loading = false
			}
		} ,
		dumpFormData() {
			const formData = {
				service_files: []
			}
			formData.service_parts = {
				repair: []
			}
			if (this.$refs.repairRef) {
				formData.job_repair = this.$refs.repairRef.getData()
				formData.service_parts.repair = this.$refs.repairRef.getPartData()
			}
			return formData
		} ,
		handleSave() {
			let hasSuccess = true

			if (this.$refs.repairRef) {
				hasSuccess = this.$refs.repairRef.validate() && hasSuccess
			}
			if (!hasSuccess) {
				this.$message.error(this.$t('onfield_service_job.action.update.validate'))
				return
			}

			const jobsFormData = this.dumpFormData()
			if (this.targetFirmwareId) {
				jobsFormData.job_firmware = {
					from_firmware_id: this.currentFirmwareId,
					to_firmware_id: this.targetFirmwareId
				}
			}
			if (!this.isDataChange(jobsFormData)) {
				this.$message.info(this.$t('onfield_service_job.action.update.no_change'))
			}

			const isRequireFieldsError = this.validateRequireFields(jobsFormData)
			if(isRequireFieldsError){
				this.$message.error(this.$t('common.error.validate_error'))
				return
			}

			this.$confirm({
				title : this.$t('onfield_service_job.action.update.confirm.title') ,
				content : this.$t('onfield_service_job.action.update.confirm.message') ,
				okText : this.$t('common.ok') ,
				cancelText : this.$t('common.cancel'),
				maskClosable : true,
				onOk: ()=> this.saveData(jobsFormData),
			})
		} ,
		async saveData(formData) {
			this.showPageLoading(true)
			try {
				await axios.put(`/api/services/${this.service.id}/jobs`,formData)
				const hasRepairPart = this.validateRepairPart(formData.service_parts?.repair)
				if(this.isClaimDrafted && !hasRepairPart){
					await axios.delete(`/api/services/${this.service.id}/claim-request`)
				}
				this.ignorePreventExit = true

				await this.updateStatusToNextPage(hasRepairPart)
			} catch (error) {
				this.saveError = error
				scrollTopContent()
			} finally {
				this.hidePageLoading()
			}
		},
		validateRequireFields(formData){
			if(!formData.job_repair) return true

			const isJobRepairAccidentEmpty = formData.job_repair.is_accident && !formData.job_repair.event_datetime
			const isJobRepairDataEmpty = !formData.job_repair.man_hours || !formData.job_repair.repair_detail_rich

			return isJobRepairAccidentEmpty || isJobRepairDataEmpty
		},
		validateRepairPart(repairParts) {
			if(repairParts?.[0]){
				const hasRepairPart = repairParts.filter(repairPart => repairPart.action !== REPAIR_PART_ACTION.REMOVE)
				return hasRepairPart.length > 0
			}
			return false
		},
		initJobs() {
			this.saveError = undefined

			this.$nextTick(() => {
				if (this.hasJobRepair && this.$refs.repairRef) {
					this.$refs.repairRef.setData(this.jobRepair,this.serviceParts)

				}
				this.$nextTick(() => {
					// Wait for inspection
					this.oldFormData = this.dumpFormData()
				})
			})
		} ,
		async updateStatusToNextPage(hasRepairPart = false) {
			try {
				await axios.post(`/api/services/${this.service.id}/update-onfield-state`, { state: hasRepairPart ? 'claim' : 'cost_estimate' })
				this.$message.success(this.$t('onfield_service_job.action.update.confirm.success'))
				this.$router.push({ name: hasRepairPart ? 'onfield/claim' : 'onfield/cost_estimate', params:{id:this.service.id} })
			} catch (error) {
				this.processError = error
			}
		},
		preventExit(event) {
			if (this.checkPreventExit()) {
				event.preventDefault()
				event.returnValue = ""
			}
		},
		isDataChange(formData) {
			const change = !_isEqual(this.oldFormData,formData)
			return change
		} ,
		checkPreventExit(formData=null) {
			if (this.ignorePreventExit)
				return false
			if(!formData) {
				formData = this.dumpFormData()
			}
			return this.isDataChange(formData)
		} ,
	} ,
	beforeRouteLeave(routeTo,routeFrom,next) {
		const formData = this.dumpFormData()
		if (this.checkPreventExit(formData)) {
			this.$confirm({
				title : this.$t('action.ask_leave.title') ,
				content : this.$t('action.ask_leave.message') ,
				okText : this.$t('common.confirm') ,
				maskClosable : true,
				onOk: ()=> {
					next()
				} ,
			})
		} else {
			next()
		}
	}
}
</script>

<style lang="less" scoped>
.title {
	font-family : @font-family-title;
	margin-bottom : 5px;
	font-weight: 600;
	color : @primary-color;
	.mobile & {
		font-size : 1em;
	}
}
.service_job-parts-pane::v-deep {
	.ant-collapse-content-box {
		background : @blue-1;
	}
}
.main-header {
	text-align: center;
}
.firmware-update {
	display: flex;
	flex-direction: row
}
.repair-section-title {
	font-family : @font-family-title;
	text-decoration: underline;
	margin-right: 5px;
	font-size : 1.1em;
	color : @secondary-color;
	margin-bottom : 12px;
}
.background {
	background : white;
	padding: 10px 10px 50px;
	.mobile & {
		padding : 12px 16px;
	}
}
.page-action-right {
	float: right;
	margin-bottom : 8px;
}
</style>
