<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
onfield_firmware_select.firmware_update: "อัพเดทเฟิร์มแวร์"
onfield_firmware_select.firmware_update.warning: "กรุณาเลือกเฟิร์มแวร์ Version ที่ต้องการอัพเดท"
onfield_firmware_select.firmware_update_detail: "เฟิร์มแวร์เดิม {name} เฟิร์มแวร์ที่อัพเดท"
onfield_firmware_select.firmware_update_modal.title: "ยกเลิกการอัพเดทเฟิร์มแวร์"
onfield_firmware_select.firmware_update_modal.content: "ต้องการยกเลิกการอัพเดทเฟิร์มแวร์หรือไม่?"

</i18n>

<template>
	<a-modal class="modal-style" :width="550" :visible="visible" :footer="null" :mask-closable="false" @cancel="handleCancel">
		<div class="modal-body">
			<span class="title">{{$t('onfield_firmware_select.firmware_update')}}</span>
			<div class="select-section">
				<span class="old-firmware">{{$t('onfield_firmware_select.firmware_update_detail', { name: firmware.name })}}</span>
				<div class="input-block">
					<FirmwareSelect
						v-model="firmwareVersion.firmware_id"
						:firmware-set-id="firmware.firmware_set_id"
						:disabled-firmwares="firmware.id"
						@changeFirmware="handleFirmwareChange" />
				</div>
			</div>
			<div class="page-action-right next-button">
				<a-button
					type="primary"
					@click="handleSave">
					{{$t('action.save')}}
				</a-button>
			</div>
		</div>
	</a-modal>
</template>

<script>
import FirmwareSelect from '@components/firmware/FirmwareSelect.vue'

export default {
	components: {
		FirmwareSelect,
	},
	props: {
		firmware: {
			type: Object,
			required: true,
		},
		latestFirmware: {
			type: Object,
      required: true,
		}
	},
	data() {
		return {
			visible: false,
			firmwareVersion : {
				firmware_name: null,
				firmware_id: null
			},
			cancelUpdate: false,
		}
	},
	mounted() {
		this.initFirmware()
	},
	methods: {
		openModal() {
			this.visible = true
		},
		handleCancel() {
			this.$confirm({
				title : this.$t('onfield_firmware_select.firmware_update_modal.title') ,
				content : this.$t('onfield_firmware_select.firmware_update_modal.content') ,
				okText : this.$t('common.yes') ,
				cancelText : this.$t('common.no'),
				onOk: ()=> {
					this.visible = false
					this.cancelUpdate = true
					this.$emit('cancelUpdate', this.cancelUpdate)
				} ,
				onCancel: ()=> {
					this.visible = false
					this.openModal()
				}
			})
		},
		handleSave() {
			if (this.firmwareVersion.firmware_id) {
				this.visible = false
			} else {
				this.$message.error(this.$t('onfield_firmware_select.firmware_update.warning'))
			}
		},
		handleFirmwareChange(val) {
			this.firmwareVersion.firmware_name = val.name
			this.$emit('firmwareUpdate', {id: val.id, name: val.name})
		},
		initFirmware() {
			this.firmwareVersion.firmware_id = this.latestFirmware.id
			this.$emit('firmwareUpdate', {id: this.latestFirmware.id, name: this.latestFirmware.name})
		},
	},
}
</script>

<style lang="less" scoped>
@part-break-screen: @screen-lg;
.next-button {
	margin: 10px 0 0 0;
}
.title {
	font-size : 1.3em;
	font-family : @font-family-title;
	margin-bottom : 5px;
	color : @primary-color;
	.mobile & {
		font-size : 1em;
	}
}
.input-block {
	display: inline-block;
	margin-right: 8px;
	.mobile & {
		display: block;
		margin-bottom: 4px;
		margin-right: 0;
	}
}
.modal-style {
	text-align: center;
}
.modal-body {
	text-align: center;
	display: flex;
	flex-direction: column;
}
.select-section {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.old-firmware {
	margin-right: 5px;
}
</style>
